import { Box, keyframes } from '@mui/material';
import { styled } from '@mui/material/styles';

const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

const ScrollContainer = styled(Box)({
  background: 'rgba(0, 0, 0, 0.5)',
  overflow: 'hidden',
  padding: '20px 0',
  height: '70px',
  whiteSpace: 'nowrap',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});

const ScrollingContent = styled(Box)({
  display: 'inline-block',
  whiteSpace: 'nowrap',
  animation: `${scroll} 20s linear infinite`,
  '& span': {
    display: 'inline-block',
    fontSize: '24px',
    padding: '0 40px',
    color: 'white',
    verticalAlign: 'middle',
  },
});

export default function ScrollingWords() {
  const words = [
    '•',
    'Embedded Systems',
    '•',
    'Real-time Monitoring',
    '•',
    'Performance Analytics',
    '•',
    'System Diagnostics',
    '•',
    'Debug Tools',
    '•',
    'Development Solutions',
  ];

  // Duplicate the words to create seamless scrolling
  const duplicatedWords = [...words, ...words];

  return (
    <ScrollContainer>
      <ScrollingContent>
        {duplicatedWords.map((word, index) => (
          <span key={index}>{word}</span>
        ))}
      </ScrollingContent>
    </ScrollContainer>
  );
}
