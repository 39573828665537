import { Box, Container, Typography, Button } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import WindowsIcon from '@mui/icons-material/Window';
import LinuxIcon from '@mui/icons-material/Computer';
import byteTracerVideo from '../media/ByteTracer.mp4';

export default function ByteTracer() {
  return (
    <Box 
      id="bytetracer"
      sx={{ 
        position: 'relative',
        overflow: 'hidden',
        py: { xs: 12, sm: 16, md: 20 },
        minHeight: '550px',
        bgcolor: 'black',
      }}
    >
      <Container 
        maxWidth={false}
        sx={{
          position: 'relative',
          zIndex: 1,
          pl: { xs: 2, sm: 4, md: 8 },
          pr: { xs: 2, sm: 4, md: 8 },
          maxWidth: '100% !important',
          height: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row-reverse' },
          alignItems: 'center',
          gap: 4,
        }}
      >
        {/* Video Section */}
        <Box sx={{
          width: { xs: '100%', md: '60%' },
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          order: { xs: 2, md: 1 },
          pr: { md: 4 }
        }}>
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '8px',
            }}
          >
            <source src={byteTracerVideo} type="video/mp4" />
          </video>
        </Box>

        {/* Content Section */}
        <Box sx={{ 
          maxWidth: '1400px',
          pl: { xs: 2, sm: 4, md: 6 },
          width: { xs: '100%', md: '50%' },
          order: { xs: 1, md: 2 }
        }}>
          <Typography
            variant="h2"
            sx={{
              color: 'white',
              mb: 4,
              textAlign: 'left',
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }
            }}
          >
            Explore Our Technologies
          </Typography>
          
          <Typography
            variant="h3"
            sx={{
              color: 'white',
              mb: 2,
              fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' }
            }}
          >
            ByteTracer
          </Typography>
          
          <Typography
            sx={{
              color: 'white',
              mb: 4,
              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' },
              maxWidth: '800px'
            }}
          >
            Real-time serial data visualizer software for microcontrollers. 
            Instantly visualize data streams with clarity and precision, 
            perfect for monitoring sensors, debugging, and enhancing system observability.
          </Typography>

          <Box sx={{ 
            display: 'flex', 
            gap: 2,
            flexWrap: 'wrap'
          }}>
            <Button
              variant="contained"
              startIcon={<AppleIcon />}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                }
              }}
              href="/software/ByteTracer-1.0.0-macOS-arm64.dmg"
            >
              Download for macOS
            </Button>
            
            <Button
              variant="contained"
              startIcon={<WindowsIcon />}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                }
              }}
              href="/software/ByteTracer-1.0.0-Windows.exe"
            >
              Download for Windows
            </Button>
            
            <Button
              variant="contained"
              startIcon={<LinuxIcon />}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                }
              }}
              href="/software/ByteTracer-1.0.0-Linux.tar.gz"
            >
              Download for Linux
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}