import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { Theme, styled, keyframes, useTheme } from '@mui/material/styles';
import axios from 'axios';
{/* 
import { GoogleIcon } from './CustomIcons';
import Divider from '@mui/material/Divider';
*/}
import TemplateFrame from './TemplateFrame';
import startwhite from '../../media/starwhite.png'; // Import the image
import startblack from '../../media/starblack.png'; // Import the image

const Card = styled(MuiCard)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  margin: 'auto',
  background: 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  border: '1px solid rgba(255, 255, 255, 0.3)',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
    padding: theme.spacing(4),
  },
  ...theme.applyStyles('dark', {
    background: 'rgba(0, 0, 0, 0.2)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
  }),
}));

const moveInCircle = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(180deg); }
  100% { transform: rotate(360deg); }
`;

const moveHorizontal = keyframes`
  0% { transform: translateX(-50%) translateY(-10%); }
  50% { transform: translateX(50%) translateY(10%); }
  100% { transform: translateX(-50%) translateY(-10%); }
`;

const StyledSignUpContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(2),
  position: 'relative',
  overflow: 'hidden',
  background: 'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
  ...theme.applyStyles('dark', {
    background: 'linear-gradient(40deg, #000B2E, #0F2B4C)',
  }),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
}));

const StyledGradientsContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  filter: 'blur(40px)',
});


const StyledGradientCircle = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'absolute',
  width: '80%',
  height: '80%',
  top: 'calc(50% - 40%)',
  left: 'calc(50% - 40%)',
  mixBlendMode: 'multiply',
  opacity: 0.7,
  ...(theme.palette.mode === 'dark' ? {
    mixBlendMode: 'hard-light',
  } : {}),
}));

const GradientCircle: React.FC<{ index: number }> = ({ index }) => {
  const animation = index === 1 ? moveInCircle : moveHorizontal;
  const background = index === 1 
    ? 'rgba(0, 71, 255, 0.15)' 
    : 'rgba(0, 150, 255, 0.15)';

  return (
    <StyledGradientCircle
      sx={{
        background: `radial-gradient(circle at center, ${background} 0%, rgba(0, 0, 0, 0) 50%)`,
        animation: `${animation} ${20 + index * 10}s ease infinite`,
      }}
    />
  );
};

const SignUp: React.FC = () => {
  const [mode, setMode] = React.useState<'light' | 'dark'>('light');
  const [emailError, setEmailError] = React.useState<boolean>(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState<string>('');
  const [passwordError, setPasswordError] = React.useState<boolean>(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState<string>('');
  const [nameError, setNameError] = React.useState<boolean>(false);
  const [nameErrorMessage, setNameErrorMessage] = React.useState<string>('');
  const theme = useTheme();

  React.useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode as 'light' | 'dark');
    } else {
      const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  const validateInputs = (): boolean => {
    const email = document.getElementById('email') as HTMLInputElement;
    const password = document.getElementById('password') as HTMLInputElement;
    const name = document.getElementById('name') as HTMLInputElement;

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters long.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    if (!name.value || name.value.length < 1) {
      setNameError(true);
      setNameErrorMessage('Name is required.');
      isValid = false;
    } else {
      setNameError(false);
      setNameErrorMessage('');
    }

    return isValid;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('handleSubmit function called');

    const data = new FormData(event.currentTarget);
    const name = data.get('name') as string;
    const email = data.get('email') as string;
    const password = data.get('password') as string;

    console.log('Form data:', { name, email, password });

    const userData = {
      name,
      email,
      password
    };

    console.log('Attempting to register user with data:', userData);

    try {
      console.log('Making POST request');
      const response = await axios.post('/api/v1/register', userData);
      console.log('Registration successful:', response.data);
    } catch (error) {
      console.error('Registration error:', error);
    }
  };

  return (
    <TemplateFrame mode={mode} toggleColorMode={toggleColorMode}>
      <StyledSignUpContainer>
        <StyledGradientsContainer>
          <GradientCircle index={1} />
          <GradientCircle index={2} />
        </StyledGradientsContainer>
        <Stack
          sx={{
            position: 'relative',
            zIndex: 1,
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 2,
            marginRight: 4,
            [theme.breakpoints.up('sm')]: {
              padding: 4,
            },
          }}
        >
          <Card>
          <img 
  src={mode === 'light' ? startblack : startwhite} 
  alt="Logo" 
  style={{ width: '20%', height: 'auto' }} 
/>          <Typography
              component="h1"
              variant="h4"
              sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
            >
              Sign up
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              <FormControl>
                <FormLabel htmlFor="name">Full name</FormLabel>
                <TextField
                  autoComplete="name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  placeholder="John Doe"
                  error={nameError}
                  helperText={nameErrorMessage}
                  color={nameError ? 'error' : 'primary'}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <TextField
                  required
                  fullWidth
                  id="email"
                  placeholder="your@email.com"
                  name="email"
                  autoComplete="email"
                  variant="outlined"
                  error={emailError}
                  helperText={emailErrorMessage}
                  color={emailError ? 'error' : 'primary'}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="password">Password</FormLabel>
                <TextField
                  required
                  fullWidth
                  name="password"
                  placeholder="••••••"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  variant="outlined"
                  error={passwordError}
                  helperText={passwordErrorMessage}
                  color={passwordError ? 'error' : 'primary'}
                />
              </FormControl>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive updates via email."
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={validateInputs}
              >
                Sign up
              </Button>
              <Typography sx={{ textAlign: 'center' }}>
                Already have an account?{' '}
                <span>
                  <Link
                    href="/material-ui/getting-started/templates/sign-in/"
                    variant="body2"
                    sx={{ alignSelf: 'center' }}
                  >
                    Sign in
                  </Link>
                </span>
              </Typography>
            </Box>
            {/*
            <Divider>
              <Typography sx={{ color: 'text.secondary' }}>or</Typography>
            </Divider>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Button
                type="button"
                fullWidth
                variant="outlined"
                onClick={() => alert('Sign up with Google')}
                startIcon={<GoogleIcon />}
              >
                Sign up with Google
              </Button>
            </Box>
            */}
          </Card>
        </Stack>
      </StyledSignUpContainer>
    </TemplateFrame>
  );
};

export default SignUp;
