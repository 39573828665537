import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { keyframes, styled } from '@mui/material/styles';
import ScrollingWords from './ScrollingWords';  // Add this import

// Add keyframe animations
const moveInCircle = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(180deg); }
  100% { transform: rotate(360deg); }
`;

const moveVertical = keyframes`
  0% { transform: translateY(-50%); }
  50% { transform: translateY(50%); }
  100% { transform: translateY(-50%); }
`;

const moveHorizontal = keyframes`
  0% { transform: translateX(-50%) translateY(-10%); }
  50% { transform: translateX(50%) translateY(10%); }
  100% { transform: translateX(-50%) translateY(-10%); }
`;

// Add styled components
const GradientBackground = styled('div')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  overflow: 'hidden',
  background: 'linear-gradient(40deg, #000B2E, #0F2B4C)',
  top: 0,
  left: 0,
});

const GradientsContainer = styled('div')({
  filter: 'blur(40px)',
  width: '100%',
  height: '100%',
});

const GradientCircle = styled('div')(({ index }: { index: number }) => ({
  position: 'absolute',
  width: '80%',
  height: '80%',
  top: 'calc(50% - 40%)',
  left: 'calc(50% - 40%)',
  background: `radial-gradient(circle at center, 
    ${index === 1 ? 'rgba(0, 71, 255, 0.8)' : 
      index === 2 ? 'rgba(0, 150, 255, 0.8)' : 
      'rgba(0, 200, 255, 0.8)'} 0%, 
    rgba(0, 0, 0, 0) 50%)`,
  mixBlendMode: 'hard-light',
  opacity: 0.7,
  animation: `${index === 1 ? moveVertical : index === 2 ? moveInCircle : moveHorizontal} ${20 + index * 10}s ease infinite`,
}));

export default function Hero() {
  const interactiveRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    let curX = 0;
    let curY = 0;
    let tgX = 0;
    let tgY = 0;

    const move = () => {
      if (!interactiveRef.current) return;
      curX += (tgX - curX) / 20;
      curY += (tgY - curY) / 20;
      interactiveRef.current.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
      requestAnimationFrame(move);
    };

    const handleMouseMove = (event: MouseEvent) => {
      tgX = event.clientX;
      tgY = event.clientY;
    };

    window.addEventListener('mousemove', handleMouseMove);
    move();

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
        height: '100vh',
        minHeight: { xs: '700px', sm: '800px' },
        position: 'relative',
        overflow: 'visible',
      }}
    >
      <GradientBackground>
        <GradientsContainer>
          {[1, 2, 3].map((i) => (
            <GradientCircle key={i} index={i} />
          ))}
          <div
            ref={interactiveRef}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              background: 'radial-gradient(circle at center, rgba(64, 149, 255, 0.8) 0, rgba(0, 0, 0, 0) 50%)',
              mixBlendMode: 'hard-light',
              opacity: 0.7,
              top: '-50%',
              left: '-50%',
            }}
          />
        </GradientsContainer>
      </GradientBackground>

      <Container
        sx={{
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          pt: { xs: 14, sm: 20, md: 30 },
          pb: { xs: '120px', sm: '140px' },
          pl: { xs: 3, sm: 13 },
          maxWidth: '100% !important',
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ 
            alignItems: 'flex-start',
            width: { xs: '100%', sm: '80%', md: '70%' }
          }}
        >
        <Typography 
          variant="h1" 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'flex-start',
            fontSize: { xs: '2.9rem', sm: '3.2rem', md: '4.0rem' },
            lineHeight: { xs: 1.6, sm: 1.3 },
            color: 'white',
            pr: { xs: 2, sm: 4, md: 6 },
            '& .textWrapper': {
              display: 'block',
              [`@media (max-width: 500px)`]: {
                '& .forcedBreak': {
                  display: 'block'
                }
              },
              [`@media (min-width: 501px)`]: {
                '& .forcedBreak': {
                  display: 'inline'
                }
              }
            }
          }}
        >
          <span className="textWrapper">
            Advancing<span className="forcedBreak"> </span>Embedded
          </span>
          Technologies
        </Typography>
          <Typography
            sx={{
              textAlign: 'left',
              color: 'white',
              width: { xs: '100%', sm: '90%', md: '60%' },
              fontSize: {
                xs: '1.5rem',
                sm: '1.5rem',
                md: '1.6rem'
              },
              lineHeight: 1.9,
            }}
          >
            Orbbyt is developing observability technologies
            across the embedded development lifecycle.
          </Typography>
        </Stack>
      </Container>

      <Box sx={{ 
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        zIndex: 10,
        bgcolor: 'black',
        mt: 4,
      }}>
        <ScrollingWords />
      </Box>
    </Box>
  );
}